import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import GET_PAGES_WITH_OLD_URLS from "apollo/queries/GET_PAGES_WITH_OLD_URLS"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

const GRAPHQL_URL = process.env.GRAPHQL_URL
if (!GRAPHQL_URL) {
  throw new Error("Please provide the GRAPHQL_URL environment variable.")
}

export const getClientServerSide = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
  }),
  cache: new InMemoryCache(),
})

export type Redirect = {
  source: string
  destination: string
}

declare global {
  interface Global {
    redirectsCache: {
      lastFetch: number
      data: Redirect[] | null
    }
  }
}

;(global as Global & typeof globalThis).redirectsCache = (global as Global & typeof globalThis)
  .redirectsCache || {
  lastFetch: 0,
  data: null,
}

export async function getCachedRedirects(pathBase: string): Promise<Redirect[] | null> {
  const oneDayInMillis = 60 * 60 * 1000 // 1 hours in milliseconds
  const now = Date.now()
  const globalObject = global as Global & typeof globalThis
  // Check if the cache is older than a day or empty
  if (
    !globalObject.redirectsCache.data ||
    now - globalObject.redirectsCache.lastFetch > oneDayInMillis
  ) {
    const { data } = await getClientServerSide.query({
      query: GET_PAGES_WITH_OLD_URLS,
      variables: {
        route: `${pathBase}`,
        culture: "he-il",
      },
    })
    // eslint-disable-next-line no-console
    console.log("FETCH REDIRECTS")
    const extractOldUrls = (nodes: any) => {
      const urls: Redirect[] = []
      nodes.forEach((node: any) => {
        node.properties.forEach((prop: any) => {
          if (prop.propertyName === "oldUrls" && prop.propertyValue.links) {
            prop.propertyValue.links.forEach((link: any) => {
              const redirect: Redirect = {
                destination: getNonUmbracoUrl(node.url.replace(/\/$/, "").replace(/\/he-IL$/, "")),
                source: link.url.replace(/\/$/, "").replace(/\/he-IL$/, ""),
              }
              urls.push(redirect)
            })
          }
        })

        // Recursively extract from children if they exist
        if (node.children && node.children.length > 0) {
          urls.push(...extractOldUrls(node.children))
        }
      })
      return urls
    }

    // Use the function to extract URLs from the GraphQL data response
    const oldUrls = extractOldUrls(data.components.edges.map((edge: any) => edge.node))

    globalObject.redirectsCache = {
      lastFetch: now,
      data: oldUrls,
    }
  } else {
  }

  return globalObject.redirectsCache.data
}

export {}

// Function to replace dynamic path placeholders, or return direct destination for plain URLs
// Function to replace dynamic path placeholders, or return direct destination for plain URLs
export const replacePath = (destination: string, params?: Record<string, string>): string => {
  if (!params) {
    return destination
  } // Return the direct destination if there are no parameters to replace
  return destination.replace(/:\w+\*/g, (match) => {
    const key = match.slice(1, -1) // Remove the colon and asterisk
    return params[key] || "" // Replace with the parameter value or empty string
  })
}

// Extract dynamic segments from the path or return {} for a direct match
export const extractParams = (pattern: string, path: string): Record<string, string> | null => {
  // Normalize the paths for comparison
  const source = decodeURI(pattern.toLowerCase().replace(/^\/|\/$/g, ""))
  const current = decodeURI(path.toLowerCase().replace(/^\/|\/$/g, ""))
  if (!source.includes(":")) {
    const match = source === current
    return match ? {} : null // Direct match for plain URLs
  }
  const regex = new RegExp("^" + source.replace(/:\w+\*/g, "(.*)") + "$", "i") // 'i' for case-insensitive regex
  const match = current.match(regex)

  return match ? { path: match[1] } : null // Assuming only one dynamic segment for simplicity
}
